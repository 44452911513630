import "babel-polyfill"
import 'vuetify/dist/vuetify.min.css'

import Vue from 'vue'

import PlatformData from '@explorelearning/platform-data';
import App from './App'
import router from './router'
import store from './state'

window.gitRevision = GIT_REVISION

new PlatformData.State({ namespace: 'platformData' }).register(store);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.axios.defaults.withCredentials = true

import { plugin as UrlsPlugin } from '@explorelearning/urls';
Vue.use(UrlsPlugin);

import Vuetify from 'vuetify'
Vue.use(Vuetify)

import VeeValidate from 'vee-validate'
const VeeValidateConfig = {
	events: 'submit',
	dictionary: {
		en: {
			messages: {
				confirmed: function(fieldName){
					return "The "+fieldName+" does not match."
				},
				digits:function(fieldName, params){
					return "The "+fieldName+" field must be numeric and contain exactly "+params[0]+" digits."
				},
				email:function(fieldName, params){
					if(params[0]=='multi'){
						return "Contains invalid email address."
					} else {
						return "The "+fieldName+" field must be a valid email address."
					}

				}
			}
		}
	}
}
VeeValidate.Validator.extend('minDigitChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' number character.'
	},
	validate(value, args){
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(/[0-9]/.test(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('minLowercaseChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' lowercase character.'
	},
	validate(value, args){
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(/[a-z]/.test(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('minSpecialChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' special character.'
	},
	validate(value, args){
		const specialChars = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(specialChars.includes(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('minUppercaseChars', {
	getMessage(field, args){
		return 'Must contain ' + args[0] + ' uppercase character.'
	},
	validate(value, args){
		let matchingChars = 0
		for(let i = 0; i < value.length; i++){
			let char = value.charAt(i)
			if(/[A-Z]/.test(char)){
				++matchingChars
			}
		}
		const count = args[0]
		return matchingChars >= count
	}
})
VeeValidate.Validator.extend('noTags', {
	getMessage: 'HTML is not allowed.',
	validate(value){
		const htmlPattern = /\<[^\ ].*\>/g
		return !htmlPattern.test(value)
	}
})
VeeValidate.Validator.extend('noDiacritics', {
	getMessage(field){
		return 'Diacritics are not allowed in '+field+'.'
	},
	validate(value){
		const diacritics = "áàâäãéèëêíìïîóòöôõúùüûñçăşţ"
		let isClean = true
		for(let char of value){
			if(diacritics.includes(char.toLowerCase())){
				isClean = false
			}
		}
		return isClean
	}
})
VeeValidate.Validator.extend('noEscapes', {
	getMessage: 'Special characters are not allowed.',
	validate(value){
		const specialChars = '@<>#%}{+|\\^~[]`;/?:=&$'
		let isClean = true
		for(let char of value){
			if(specialChars.includes(char)){
				isClean = false
			}
		}
		return isClean
	}
})
VeeValidate.Validator.extend('noEscapesExceptAt', {
	getMessage: 'Special characters are not allowed.',
	validate(value){
		const specialChars = '<>#%}{+|\\^~[]`;/?:=&$'
		let isClean = true
		for(let char of value){
			if(specialChars.includes(char)){
				isClean = false
			}
		}
		return isClean
	}
})
VeeValidate.Validator.extend('noSpaces', {
	getMessage: 'Spaces are not allowed.',
	validate(value){
		const space = ' '
		let isClean = true
		for(let char of value){
			if(char == space){
				isClean = false
			}
		}
		return isClean
	}
})
Vue.use(VeeValidate, VeeValidateConfig)

import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy, {
	directive: 'tippy', // => v-tippy
	flipDuration: 0,
	popperOptions: {
	  modifiers: {
		preventOverflow: {
		  enabled: true
		}
	  }
	}
})
Vue.component('tippy', TippyComponent);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import i18n from './lang'
Vue.use(i18n)

import VueGtag from "vue-gtag"
Vue.use(VueGtag, { config: { id: 'GTM-TZBD7TT' } }, router)

new Vue({
	el: '#app',
	router,
	store,
	i18n,
	components: { App },
	template: '<App/>',
	render: h => h(App)
})
